import React from "react";
import { graphql } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import Layout from "../../components/layout";
import Menu from "../../components/Menu-en";
import Footer from "../../components/Footer-en";
import { Helmet } from "react-helmet";
import Social from "../../components/Social-en";
import { Container, Row, Col } from "glamorous-grid";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { FaCheckCircle } from "react-icons/fa";
import { FaUserMd } from "react-icons/fa";
import { IoIosBody } from "react-icons/io";
import { FaHome } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";

export default function Qualifications({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const {
    portrait,
    medailles,
    lvpa,
    keybodylipo,
    keybodyskin,
    portraitbis,
  } = data;

  const Wrapper = g.main({
    maxWidth: `1000px`,
    margin: `0 auto`,
    paddingBottom: `4em`,
  });

  const Title = g.h1({
    textAlign: `center`,
    fontSize: `32px`,
    color: `#3c4d68`,
    maxWidth: `650px`,
    margin: `1.2em auto .6em`,
    fontVariant: `none`,
    fontWeight: `600`,
    letterSpacing: `.01em`,
    lineHeight: `44px`,
    "@media(max-width: 767px)": {
      fontSize: `23px`,
      lineHeight: `31px`,
    },
  });

  const H2 = g.h2({
    position: `relative`,
    marginBottom: `.5em`,
    "&::after": {
      content: ` `,
      position: `absolute`,
      top: `54%`,
      left: `250px`,
      border: `.088em solid #3c4d68`,
      display: `inline-block`,
      width: `715px`,
    },
    "@media(max-width: 767px)": {
      margin: `.5em 0 .2em`,
      "&::after": {
        content: `none`,
      },
    },
    "@media(max-width: 1100px)": {
      "&::after": {
        content: `none`,
      },
    },
  });

  const Title2 = g.h2({
    position: `relative`,
    marginBottom: `.5em`,
    "&::after": {
      content: ` `,
      position: `absolute`,
      top: `54%`,
      left: `375px`,
      border: `.088em solid #3c4d68`,
      display: `inline-block`,
      width: `585px`,
    },
    "@media(max-width: 767px)": {
      margin: `.5em 0 .2em`,
      "&::after": {
        content: `none`,
      },
    },
    "@media(max-width: 1100px)": {
      "&::after": {
        content: `none`,
      },
    },
  });

  const Title2bis = g.h2({
    position: `relative`,
    marginBottom: `.5em`,
    "&::after": {
      content: ` `,
      position: `absolute`,
      top: `54%`,
      left: `400px`,
      border: `.088em solid #3c4d68`,
      display: `inline-block`,
      width: `575px`,
    },
    "@media(max-width: 767px)": {
      margin: `.5em 0 .2em`,
      "&::after": {
        content: `none`,
      },
    },
    "@media(max-width: 1100px)": {
      "&::after": {
        content: `none`,
      },
    },
  });

  const Ul = g.ul({
    listStyleType: `none`,
    margin: `.2em 0 .2em`,
    marginBottom: `2em`,
    "@media(max-width: 767px)": {
      margin: `0 0 1em 0 !important`,
    },
  });

  const Li = g.li({
    margin: `.7em 0 .8em`,
    "@media(max-width: 767px)": {
      margin: `.4em 0 .5em`,
      lineHeight: `22px`,
    },
  });

  const cssCheck = css({
    color: `#3c4d68`,
    marginRight: `1em`,
    fontSize: `15px`,
    paddingBottom: `3px`,
    transform: `translateY(3px)`,
    "@media(max-width: 767px)": {
      marginRight: `.5em`,
    },
  });

  const cssPicto1 = css({
    color: `#3c4d68`,
    marginRight: `.15em`,
    fontSize: `46px`,
    marginBottom: `-15px`,
  });

  const cssPicto2 = css({
    color: `#3c4d68`,
    marginRight: `.25em`,
    fontSize: `36px`,
    marginBottom: `-10px`,
  });

  const ccPictoLink = css({
    textAlign: `center`,
    textDecoration: `none`,
    color: `#3c4d68`,
    paddingTop: `1
    4px`,
  });

  const Tile = g.div({
    margin: `1em 0 0`,
    backgroundColor: `white`,
    color: `#1f1f1f`,
    border: `1px solid rgba(73,105,126,0.08)`,
    boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
    borderRadius: `4px`,
    transition: `all 0.15s ease-in-out`,
    ":hover": {
      border: `1px solid rgba(73,105,126,0.18)`,
      boxShadow: `0 1px 9px rgba(0,0,0,.2)`,
      transition: `all 0.1s ease-in-out`,
    },
  });

  const H3Block = g.h3({
    textAlign: `center`,
    margin: `.9em 0 .5em`,
    color: `#3c4d68`,
    letterSpacing: `.08em`,
    textDecoration: `none`,
    fontSize: `17px`,
    fontWeight: `bold`,
  });

  const cssLinkH3Block = css({
    textDecoration: `none`,
  });

  const TileText = g.p({
    padding: `0 .8em`,
    lineHeight: `23px`,
    fontSize: `15px`,
  });

  const More = g.div({
    backgroundColor: `#3c4d68`,
    color: `white`,
    fontSize: `13px`,
    padding: `.35em .5em .4em`,
    width: `100px`,
    margin: `1.2em auto 1.8em`,
    textAlign: `center`,
    borderRadius: `5px`,
  });

  const Breadcrumb = g.ul({
    fontSize: `13px`,
    lineHeight: `20px`,
    marginLeft: `0 !important`,
    marginTop: `0`,
    marginBottom: `10px !important`,
    fontWeight: `500`,
    letterSpacing: `.015em`,
  });

  const BreadcrumbItem = g.li({
    display: `inline`,
    "&:first-of-type": {
      "&::before": {
        content: `none`,
      },
    },
    "&::before": {
      content: `/`,
      padding: `0 .5em`,
    },
  });

  const cssBreadcrumbLink = css({
    color: `#3c4d68`,
    borderBottom: `none`,
  });

  const IframeWrapper = g.div({
    padding: `56.25% 0 0 0`,
    position: `relative`,
  });

  const cssIframe = css({
    border: `none`,
    width: `100%`,
    position: `absolute`,
    top: `0`,
    left: `0`,
    height: `100%`,
  });

  const cssButton = css({
    color: `white`,
    backgroundColor: `#3c4d68`,
    border: `1px solid #3c4d68`,
    padding: `.4em 1.5em`,
    display: `block`,
    margin: `1em 0 12px`,
    borderRadius: `5px`,
    textAlign: `center`,
    fontWeight: `500`,
    fontSize: `15px`,
    width: `200px`,
    float: `right`,
    textDecoration: `none`,
    letterSpacing: `.03em`,
    transition: `all 0.3s ease-in-out 0s`,
    ":hover": {
      backgroundColor: `white`,
      color: `#3c4d68`,
      transition: `all 0.3s ease-in-out 0s`,
    },
  });

  return (
    <Layout>
      <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
        <Helmet>
          <title>Dr. Cédric Kron - Plastic surgeon in Paris</title>
          <meta
            name="description"
            content="Dr. Cédric Kron is among the best plastic surgeon in Paris. He is qualified and recognized in plastic, cosmetic and reconstructive surgery as well as in aesthetic medicine."
          />
          <html lang="en" />
          <link
            rel="alternate"
            hreflang="fr"
            href="https://www.dr-kron.com/chirurgien-esthetique-paris/"
          />
          <script type="application/ld+json">{` {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Dr. Cédric Kron, plastic surgeon in Paris",
          "item": "https://www.dr-kron.com/en/plastic-surgeon-paris/"
          }]
              }
    `}</script>
        </Helmet>
        <Menu current="qualifications" pathFr="/chirurgien-esthetique-paris/" />
        <Wrapper>
          <Container css={{ padding: `0 15px !important` }}>
            <section>
              <Title>Dr. Cédric Kron, plastic surgeon in Paris</Title>
              <Img
                fluid={portrait.childImageSharp.fluid}
                css={{
                  margin: `1em auto 1.2em`,
                  "@media only screen and (max-width: 600px)": {
                    marginLeft: `calc(50% - 49.9vw)`,
                    marginRight: `calc(50% - 49.9vw)`,
                    marginBottom: `1em`,
                  },
                }}
                alt="Dr Cédric Kron, plastic surgeon in Paris"
              />

              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/" {...cssBreadcrumbLink} title="Homepage">
                    <FaHome
                      css={{
                        fontSize: `1.2em`,
                        paddingBottom: `.2em`,
                        transform: `translateY(5px)`,
                      }}
                    />
                  </Link>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  Dr. Cédric Kron, plastic surgeon in Paris
                </BreadcrumbItem>
              </Breadcrumb>

              <p className="intro">
                Are you looking for a serious, renown and caring plastic surgeon
                in Paris? Dr. Cédric Kron was trained by the best aesthetic
                surgeons in Paris. His degrees and diplomas, his scientific
                participation in international congresses as well as his{" "}
                <Link to="/en/testimonials/">patient's testimonials</Link> have
                proven his dedication in supporting you in full compliance with
                best practices recommended by the Council of the Order of
                Doctors (Articles 32 and 33 of the Code of Ethics of
                Physicians).
              </p>
              <p>
                <strong>
                  Former winner of a{" "}
                  <a
                    href="http://www.aaihp.fr/MedailleAPHP.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Gold Medal
                  </a>{" "}
                  from Surgery Hospitals of Paris
                </strong>
                , he is qualified in Plastic Surgery, Reconstructive and
                aesthetic medicine, the official diploma recognized in France.
                He is registered as a plastic surgeon with the{" "}
                <a
                  href="https://www.conseil-national.medecin.fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Council of the Order of Doctors
                </a>{" "}
                under the registration number 66193.
              </p>

              <p>
                Plastic, Reconstructive and Aesthetic Surgery is an official
                qualification in France awarded by the National Council of the
                Order of Physicians, that verifies and certifies the cosmetic
                surgeon's skills.
              </p>
              <p>
                Dr. Kron exercices as an aesthetic and plastic surgeon{" "}
                <strong>in Paris only</strong>, exclusively in the private
                sector, which allows him to care for his patients 24 hours a day
                when necessary. He consults in his own office located in the
                Northwestern area of Paris (17th). It is a{" "}
                <Link to="/en/plastic-surgery-practice/">practice</Link>{" "}
                dedicated to a comprehensive and tailor-made approach of
                aesthetic treatments. He always performs treatments in approved
                clinics for the aesthetic surgery, in particular the Nouvelle
                Clinique d'Esthétique des Champs-Elysées (Paris 8).
              </p>
              <p>
                Dr. Cédric Kron is a cosmetic surgeon licensed under health
                insurance plans (sector 2). The procedures registered in the
                nomenclature of medical acts covered by social security may be
                reimbursed in part or in full by the health insurance (CPAM) and
                supplementary health insurance ("mutuelles complémentaires" in
                French).
              </p>
            </section>
            <section>
              <Row>
                <Col>
                  <H2>Degrees & diplomas</H2>
                  <Img
                    fluid={medailles.childImageSharp.fluid}
                    css={{
                      margin: `1em auto 1.2em`,
                      "@media only screen and (max-width: 600px)": {
                        marginLeft: `calc(50% - 49.9vw)`,
                        marginRight: `calc(50% - 49.9vw)`,
                        marginBottom: `1em`,
                      },
                    }}
                    alt="Degrees, medals and diplomas of Dr Kron, plastic surgeon in Paris"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {" "}
                  <Ul>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Surgeon Qualified in Plastic, Reconstructive and Aesthetic
                      Surgery.
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Associated member of the French National Academy of
                      Surgery
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Graduate of D.E.S.C. of Plastic Reconstructive and
                      aesthetic Surgery.
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Former Head of Clinic at the Faculty of Medicine
                      Saint-Antoine de Paris
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Former Assistant of&nbsp;
                      <a
                        href="https://www.aphp.fr/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Assistance Publique - Hôpitaux de Paris (APHP)"
                      >
                        Paris Hospitals (APHP)
                      </a>{" "}
                      (Department of Professor M. Mimoun at Rothschild Hospital)
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Gold Medal of Surgery Hospital Paris (2000). Only two
                      plastic surgeons have been awarded this distinction so
                      far.
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Prize of the National Academy of Surgery (2000)
                    </Li>
                  </Ul>
                </Col>
                <Col>
                  <Ul>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Former Practitioner-Attached to the Rothschild Hospital
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Former Harvard Medical School student - Children Hospital
                      of Boston (1992)
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Inter-University Diploma of Microsurgery
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Inter-University Diploma of Surgery of the Hand and Upper
                      Limb
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Diploma of Advanced Teaching in Surgical Sciences
                      (Transplantation option)
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Master of Anatomy: Certificate of General Anatomy and
                      Certificate of Vascular Anatomy
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Laureate of{" "}
                      <a
                        href="https://www.medecine.parisdescartes.fr/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Faculté de Médecine de Paris
                      </a>
                    </Li>
                  </Ul>
                </Col>
              </Row>
            </section>
            <section>
              <Title2>His approach of plastic surgery</Title2>
              <Row>
                <Col span={{ sm: 12 / 12, md: 4 / 12 }}>
                  <Img
                    fluid={portraitbis.childImageSharp.fluid}
                    css={{ marginBottom: `.7em` }}
                    alt="Portrait of Dr. Cédric Kron, Plastic surgeon in Paris 17th arrondissement"
                  />
                </Col>
                <Col span={{ sm: 12 / 12, md: 8 / 12 }}>
                  <p>
                    Dr. Cédric Kron performs all treatments related to plastic,
                    aesthetic and reconstructive surgery as well as and
                    aesthetic medicine.
                  </p>
                  <p>
                    Among these surgeries, he often performs procedures such as
                    &nbsp;
                    <Link to="/en/face/facelift-lvpa/">
                      LVPA facelift
                    </Link>, <Link to="/en/nose/rhinoplasty/">rhinoplasty</Link>{" "}
                    and breast surgery (
                    <Link to="/en/breast/breast-implants/">
                      breast implants
                    </Link>{" "}
                    and <Link to="/en/breast/breast-lift/">breast lift</Link>).
                  </p>
                  <p>
                    All the medicine and plastic surgery treatments he performs
                    have been proven <strong>safe and effective</strong>.
                  </p>
                </Col>
              </Row>

              <Row>
                <Col
                  css={{
                    textAlign: `center`,
                    marginTop: `1.5em`,
                    marginBottom: `1em`,
                  }}
                >
                  <Link to="/en/treatments/" {...ccPictoLink}>
                    <IoIosBody {...cssPicto1} />
                    Treatments performed by body region
                  </Link>
                </Col>
                <Col css={{ textAlign: `center`, marginTop: `1.68em` }}>
                  <Link to="/en/procedures/" {...ccPictoLink}>
                    <FaUserMd {...cssPicto2} /> Type of procedures performed by
                    Dr Kron
                  </Link>
                </Col>
              </Row>
            </section>

            <section>
              <h3>Limited recovery time and natural results</h3>
              <p>
                Dr. Kron meets the requirements of patients who wish to combine
                the{" "}
                <strong>
                  benefits of both cosmetic surgery and aesthetic medicine
                </strong>
                . On one hand, he uses the clear and indisputable results
                allowed by cosmetic surgery. On the other hand, he favors low
                risks and limited side effects of medical techniques.
              </p>
              <p>
                For example, a breast augmentation with implants that he
                performs are often performed with a outpatient hospitalization.
                The patients are very often able to take a shower and move as
                early as the evening of the procedure.
              </p>

              <p>
                He offers surgical interventions with{" "}
                <strong>limited recovery times</strong> and aesthetic medicine
                treatments with effective and longlasting results. The double
                competence of doctor and plastic surgeon allows him to associate
                surgical and medical techniques within the same procedure for an
                optimal result. For any procedure, priority is always given to
                the natural appearance of the result and the adequacy of the
                proposed treatments with the wishes and anatomy of the patients.
              </p>
              <h3>A tailor-made approach of aesthetic treatments</h3>
              <p>
                All of the plastic surgery procedures performed by Dr. Cédric
                Kron are developed in a tailor-made approach before, during and
                after the procedure.
              </p>
              <p>
                A specific physiotherapy and/or osteopathic care developed with
                Dr. Kron is sometimes proposed to accelerate the recovery
                process.
              </p>
              <p>
                As part of this tailor-made approach and emphasis on fast
                recovery, Dr. Kron has implemented a protocol for post-operative
                rehabilitation, recovery coaching and strengthening of the body
                using EMS (
                <Link to="/en/body-contouring/xbody-reshape-body-ems/">
                  electro-myo-stimulation with XBody
                </Link>
                ).
              </p>
            </section>
            <section>
              <H2>Scientific activities</H2>
              <Row>
                <Col span={{ sm: 12 / 12, lg: 6 / 12 }}>
                  <p>
                    Dr. Kron is recognized in scientific circles and
                    participates or organizes each year several international
                    congresses and scientific meetings with the best cosmetic
                    surgeons. He is a member of the French Society of Plastic,
                    Reconstructive and Aesthetic Surgery (
                    <a
                      href="https://www.sofcpre.fr/chirurgiens/dr-kron-cedric.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SoFCPRE
                    </a>
                    ), the French Society of Cosmetic Plastic Surgeons (
                    <a
                      href="http://www.chirurgiens-esthetiques-plasticiens.com/membres/cedrickron/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SOFCEP
                    </a>
                    ) and the National Union of Plastic, Reconstructive and
                    Aesthetic Surgery (
                    <a
                      href="http://www.sncpre.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SNCPRE
                    </a>
                    ).
                  </p>
                  <p>
                    He is also a correspondent and scientific expert for French
                    and foreign <strong>medical laboratories</strong>,
                    particularly in the field of cosmetic fillers and research
                    on aging.
                  </p>
                  <p>
                    In addition to his publications in the scientific field, Dr.
                    Kron collaborates regularly with the press on themes such as
                    plastic surgery, aging and medical advances.
                  </p>
                </Col>
                <Col span={{ sm: 12 / 12, lg: 6 / 12 }}>
                  <IframeWrapper>
                    <iframe
                      src="https://player.vimeo.com/video/84618487?color=2b6982&amp;title=0&amp;byline=0&amp;portrait=0"
                      title="Reportage vidéo : Science et rajeunissement esthétique"
                      {...cssIframe}
                    />
                  </IframeWrapper>
                  <Link {...cssButton} to="/video-chirurgie-esthetique/">
                    <FaPlay
                      css={{ marginRight: `10px`, marginBottom: `-2px` }}
                    />
                    All videos (in French)
                  </Link>
                </Col>
              </Row>
            </section>
            <section>
              <Title2bis>Procedures designed by Dr. Kron</Title2bis>
              <p className="intro">
                As a plastic surgeon in Paris, Dr. Cédric Kron performs all
                current aesthetic treatments that have proven their safety and
                effectiveness. He also developed a facelift technique as well as
                two combined aesthetic medicine protocols, one for fat removal,
                the other for deep rejuvenation of the skin.
              </p>
              <Row>
                <Col span={{ sm: 12 / 12, md: 4 / 12 }}>
                  <Tile>
                    <Link to="/en/face/facelift-lvpa/">
                      <Img
                        fluid={lvpa.childImageSharp.fluid}
                        alt="Facelift LVPA, a plastic surgery procedure of the face"
                      />
                    </Link>
                    <Link to="/en/face/facelift-lvpa/" {...cssLinkH3Block}>
                      <H3Block>Facelift LVPA</H3Block>
                    </Link>
                    <TileText>
                      The LVPA facelift (Deep Vertical Anterior Facelift) offers
                      natural and long-lasting results, as well as rapid and
                      painless recovery thanks to very limited risks of
                      hematomas and naturally hidden scars.
                      <br />
                      &nbsp;
                    </TileText>

                    <div>
                      <Link
                        to="/en/face/facelift-lvpa/"
                        title="Information about Facelift LVPA"
                      >
                        <More>Learn more</More>
                      </Link>
                    </div>
                  </Tile>
                </Col>
                <Col span={{ sm: 12 / 12, md: 4 / 12 }}>
                  <Tile>
                    <Link to="/en/body-contouring/keybody-lipo/">
                      <Img
                        fluid={keybodylipo.childImageSharp.fluid}
                        alt="Fat removal with Keybody-Lipo"
                      />
                    </Link>
                    <Link
                      to="/en/body-contouring/keybody-lipo/"
                      {...cssLinkH3Block}
                    >
                      <H3Block>Keybody-Lipo</H3Block>
                    </Link>
                    <TileText>
                      Keybody-Lipo is an innovative and non-invasive protocol
                      for permanent fat removal and muscle sculpting of the
                      body. The Keybody-Lipo protocol can be considered as a
                      nonsurgical alternative to a "liposuction".
                    </TileText>

                    <div>
                      <Link
                        to="/en/body-contouring/keybody-lipo/"
                        title="Fat removal with Keybody-Lipo"
                      >
                        <More>Learn more</More>
                      </Link>
                    </div>
                  </Tile>
                </Col>
                <Col span={{ sm: 12 / 12, md: 4 / 12 }}>
                  <Tile>
                    <Link to="/en/skin/keybody-skin/">
                      <Img
                        fluid={keybodyskin.childImageSharp.fluid}
                        alt="Keybody-Skin, a skiin imperfection treatments"
                      />
                    </Link>
                    <Link to="/en/skin/keybody-skin/" {...cssLinkH3Block}>
                      <H3Block>Keybody-Skin</H3Block>
                    </Link>
                    <TileText>
                      This skin rejuvenation protocol addresses concerns of
                      patients who want to correct skin imperfections (scars,
                      spots, enlarged pores) and combat the effects of aging
                      (wrinkles, loss of elasticity and firmness).
                    </TileText>

                    <div>
                      <Link
                        to="/en/skin/keybody-skin/"
                        title="Information about Keybody-Skin"
                      >
                        <More>Learn more</More>
                      </Link>
                    </div>
                  </Tile>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Social
                    path="https://www.dr-kron/en/plastic-surgeon-paris/"
                    title="Dr Cédric Kron - Plastic surgeon in Paris"
                  />
                </Col>
              </Row>
            </section>
          </Container>
        </Wrapper>

        <Footer />
      </g.Div>
    </Layout>
  );
}

export const QualificationsQuery = graphql`
  query MyQueryNameEn {
    portrait: file(
      relativePath: {
        eq: "images/dr-cedric-kron-chirurgien-esthetique-paris.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 970) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    medailles: file(
      relativePath: {
        eq: "images/medailles-chirurgien-esthetique-reconnu-dr-kron.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 970) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    lvpa: file(relativePath: { eq: "images/lifting-visage-lvpa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 306) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    keybodylipo: file(
      relativePath: { eq: "images/keybody-lipo-supprimer-graisses.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 306) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    keybodyskin: file(relativePath: { eq: "images/keybody-skin.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 306) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    portraitbis: file(
      relativePath: {
        eq: "images/dr-cedric-kron-chirurgie-esthetique-paris.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
