import React from "react";
import g from "glamorous";
import { css } from "glamor";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton
} from "react-share";
import { FaFacebookSquare } from "react-icons/fa/";
import { FaTwitter } from "react-icons/fa";
import { FaEnvelope }  from "react-icons/fa";

const ShareWrapper = g.div({
  marginTop: `25px`,
  display: `flex`,
  justifyContent: `flex-end`
});

const Share = g.div({
  display: `inline-block`,
  fontWeight: `600`,
  fontSize: `0.85em`,
  textTransform: `uppercase`,
  padding: `.4em .3em 0 0`
});

const Circle = g.div({
  display: `inline-block`,
  textAlign: `center`,
  width: `30px`,
  height: `30px`,
  marginBottom: `0`,
  marginRight: `2px`,
  fontSize: `1.4em`,
  border: `2px solid #fcfcfc`,
  borderRadius: `50%`,
  position: `relative`,
  transition: `all 0.2s ease-in-out 0s`,
  ":hover": {
    backgroundColor: `white`,
    transition: `all 0.2s ease-in-out 0s`,
    border: `2px solid #3c4d68`,
    background: `#3c4d68`
  },
  ":hover svg": {
    color: `white`,
    transition: `all 0.2s ease-in-out 0s`
  }
});

let cssFa = css({
  color: `#3c4d68`,
  marginBottom: `.2em`,
  transition: `all 0.2s ease-in-out 0s`,
  position: `absolute`,
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`
});

export default ({ ...props }) => (
  <ShareWrapper>
    <Share>Share:</Share>
    <FacebookShareButton url={`http://www.dr-kron.com${props.path}`}>
      <Circle>
        <FaFacebookSquare size="18" {...cssFa} />
      </Circle>
    </FacebookShareButton>
    <TwitterShareButton
      url={`http://www.dr-kron.com/${props.path}`}
      title={props.title}
    >
      <Circle>
        <FaTwitter size="18" {...cssFa} />
      </Circle>
    </TwitterShareButton>
    <EmailShareButton
      url={`http://www.dr-kron.com/${props.path}`}
      subject={props.title}
      body={`http://www.dr-kron/${props.path}`}
    >
      <Circle>
        <FaEnvelope size="18" {...cssFa} />
      </Circle>
    </EmailShareButton>
  </ShareWrapper>
);
